const employeeColors = {
  list: [
    { text: "#AC421A", background: "#FFE5DB" },
    { text: "#867717", background: "#FFFDE8" },
    { text: "#677813", background: "#F9FFDA" },
    { text: "#2C670F", background: "#E7FFDB" },
    { text: "#115F69", background: "#E4FCFF" },
    { text: "#163295", background: "#E1E8FF" },
    { text: "#72104A", background: "#FFD7EE" },
    { text: "#263238", background: "#F8C8C8" },
    { text: "#E55721", background: "#F8D5C8" },
    { text: "#E59821", background: "#F8E5C8" },
    { text: "#E5D121", background: "#F8F4C8" },
    { text: "#AFCC1F", background: "#EBF2C7" },
    { text: "#83CC1F", background: "#E0F2C7" },
    { text: "#58CC1F", background: "#D5F2C7" },
    { text: "#1FCC49", background: "#C7F2D2" },
    { text: "#1FCC86", background: "#C7F2E1" },
    { text: "#1FCCB8", background: "#C7F2ED" },
    { text: "#1FB8CC", background: "#C7EDF2" },
    { text: "#1FA1CC", background: "#C7E8F2" },
    { text: "#1F89CC", background: "#ABD3EC" },
    { text: "#1F6BCC", background: "#ABC7EC" },
    { text: "#1F46CC", background: "#C7D1F2" },
    { text: "#221FCC", background: "#ACABEC" },
    { text: "#5E1FCC", background: "#C3ABEC" },
    { text: "#831FCC", background: "#D1ABEC" },
    { text: "#AC1FCC", background: "#E0ABEC" },
    { text: "#CC1F85", background: "#ECABD1" },
  ],
  defaultEmployeeColor: "#DCFFDB",
  defaultEventBackgroundColor: "#DCFFDB",
  defaultEventTextColor: "#2ECC71",
  defaultUnavailableBookingSpotBackgroundColor: "#F4E3FF",
  defaultUnavailableBookingSpotTextColor: "#D688E2",

  defaultCanceledBookingBackgroundColor: "#F8C8C8",
  defaultCanceledBookingTextColor: "#E52121",

  getTextColorForBackgroundColor(backgroundColor) {
    const result = employeeColors.list.find(
      (obj) => obj.background === backgroundColor
    );

    if (result) {
      return result.text;
    }

    return employeeColors.defaultEventTextColor;
  },
};

export default employeeColors;
