import helpers from "@/lib/calendesk-js-library/tools/helpers";

export class GroupEventData {
  constructor() {
    this.allDay = null;
    this.employee = null;
    this.users = [];
    this.duration = null;
    this.service = null;
    this.startDate = null;
    this.endDate = null;
    this.startTime = null;
    this.endTime = null;
    this.startsAt = null;
    this.endsAt = null;
    this.type = helpers.eventTypes.groupBooking;
    this.groupId = null;
    this.recurrenceParams = null;
    this.location = null;
    this.googleMeetUrl = null;
    this.teamsUrl = null;
    this.skypeUrl = null;
    this.customerWhatsAppUrl = null;
    this.employeeWhatsAppUrl = null;
    this.key = Math.floor(Math.random() * 100000000);
    this.duplicate = false;
    this.updateWithoutAllData = false;
    this.data = null;
    this.multiSlotGroupId = null;
    this.calendarFullDate = null;
    this.bookings = [];
  }
}
