import helpers from "@/lib/calendesk-js-library/tools/helpers";

export class EventData {
  constructor() {
    this.id = null;
    this.allDay = null;
    this.employee = null;
    this.user = null;
    this.users = [];
    this.tags = [];
    this.products = [];
    this.description = null;
    this.duration = null;
    this.userName = null;
    this.userSurname = null;
    this.service = null;
    this.startsAt = null;
    this.endsAt = null;
    this.startDate = null;
    this.endDate = null;
    this.startTime = null;
    this.endTime = null;
    this.type = helpers.eventTypes.booking;
    this.status = null;
    this.groupId = null;
    this.recurrenceParams = null;
    this.paid = false;
    this.location = null;
    this.googleMeetUrl = null;
    this.teamsUrl = null;
    this.skypeUrl = null;
    this.customerWhatsAppUrl = null;
    this.employeeWhatsAppUrl = null;
    this.key = Math.floor(Math.random() * 100000000);
    this.duplicate = false;
    this.updateWithoutAllData = false;
    this.data = null;
    this.multiSlotGroupId = null;
    this.createdAt = null;
    this.createdBy = null;
    this.updatedAt = null;
    this.updatedBy = null;
    this.calendarFullDate = null;
    this.draftUuid = null;
    this.control = null;
    this.paymentTransaction = null;
    this.simpleStoreProductPaymentTransactions = null;
    this.invoiceNumber = null;
    this.customFields = null;
  }
}
